/* eslint-disable no-console */
import Tracker from '@oneflare/flarekit/lib/libs/Tracker/Tracker';
import { emitter } from '@marvelapp/react-ab-test';
import { publicRuntimeConfig } from 'lib/utils/Environment';

export const trackExperiment = (jiraTicket: string, variantName: string) => {
  // Snowplow integration instructions
  // https://oneflare.atlassian.net/wiki/spaces/OPT/pages/32833539/Experiment+DataLayer+Setup+Instructions
  if (jiraTicket && variantName) {
    // Extra variant number from variantName
    // Default to 1 for control value
    const extractVariantNumber = variantName.match(/\d+/g) ? Number(variantName.match(/\d+/g)[0]) : 1;
    Tracker.dataLayer.push({
      event: 'landings',
      action: 'landing',
      category: 'internal_ab_testing',
      label: jiraTicket,
      value: extractVariantNumber
    });
  }
};

export const setupExperiment = (
  jiraTicket: string,
  variantNames: Array<string>,
  variantWeights: Array<number>,
  sessionId: string
) => {
  if (sessionId) {
    emitter.defineVariants(jiraTicket, variantNames, variantWeights);
    emitter.addPlayListener((experimentName: unknown, variantName: string) => {
      trackExperiment(jiraTicket, variantName);
      // Show debug info on all env except production
      if (publicRuntimeConfig.ENVIRONMENT !== 'production') {
        const experimentInfo = {};

        class VariantInfo {
          snowplowId: number;

          weightPercentage: string;

          isActive: string;

          constructor(snowplowId: number, weightPercentage: string, isActive: string) {
            this.snowplowId = snowplowId;
            this.weightPercentage = weightPercentage;
            this.isActive = isActive;
          }
        }

        console.group(`🧪🧪🧪 Experiment https://oneflare.atlassian.net/browse/${jiraTicket} running`);
        console.log(`Session ID: ${sessionId}`);
        variantNames.forEach((name, index) => {
          experimentInfo[name] = new VariantInfo(
            name.match(/\d+/g) ? Number(name.match(/\d+/g)[0]) : 1,
            `${variantWeights[index].toFixed(1)}%`,
            name === variantName ? '✅' : '❌'
          );
        });
        console.table(experimentInfo);
        console.groupEnd();
      }
    });
  } else {
    console.error('🧪🧪🧪 Experiment halted - ❌ Missing SessionID');
  }
};
