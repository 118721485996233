import cookieCutter from '@boiseitguru/cookie-cutter';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { memo } from 'react';

import { experimentJira, experimentVariant, experimentWeights } from 'shared/utils/constants';
import { setupExperiment } from 'shared/utils/experiment';
import type {
  CategoryRootPageProps
} from 'types/oneflare.com.au/categoryRoot';

import { getCategoryRootPageContent } from './utils/getCategoryRootPageContent';
import {
  usePageTracking
} from './utils/hooks';

// #region lazy loaded components
const CategoryLayout = dynamic(() => import('./components/CategoryLayout'));
const CategoryDefault = dynamic(() => import('./components/CategoryDefault'));
const CategoryEntry = dynamic(() => import('./components/CategoryEntry'));
// #endregion lazy loaded components

const CategoryInner = ({
  innerProps, isEntryPage
}) => {
  if (isEntryPage) return <CategoryEntry {...innerProps} />;
  return <CategoryDefault {...innerProps} />;
};

const Category = (props: CategoryRootPageProps) => {
  const {
    categoryRoot: categoryRootData,
    categoryAverageRating,
    entryPointPage,
    isEntryPage
  } = props;
  const { pathname, query } = useRouter();
  const sessionId = typeof window !== 'undefined' && cookieCutter.get('sessionId');
  const pageTracking = usePageTracking(categoryRootData);
  const content = getCategoryRootPageContent(props, { pathname, query }, pageTracking, sessionId);
  const {
    breadCrumbsProps,
    businessesPaginationProps,
    headContentProps,
    headerProps,
    heroProps,
    mainCityProps,
    params,
    renderChoiceTipsProps
  } = content;

  const categoryRootInnerContentProps = {
    isEntryPage,
    entryPointPage,
    categoryRootData,
    categoryAverageRating,
    params
  };

  return (
    <>
      {sessionId && setupExperiment(
        experimentJira,
        experimentVariant,
        experimentWeights,
        sessionId
      )}
      <CategoryLayout
        {...{
          headContentProps,
          headerProps,
          heroProps,
          mainCityProps,
          breadCrumbsProps,
          businessesPaginationProps,
          params,
          isEntryPage,
          entryPointPage,
          renderChoiceTipsProps,
          categoryRootData
        }}
      >
        <CategoryInner innerProps={categoryRootInnerContentProps} isEntryPage={isEntryPage} />
      </CategoryLayout>
    </>
  );
};

Category.defaultProps = {
  params: {},
  categoryChoiceTips: {},
  entryPageChoiceTips: [],
  entryPointPage: {
    averageRating: {
      businessCount: 0,
      rating: 0,
      reviewsCount: 0
    },
    entryPoint: {
      plural: ''
    }
  },
  mainCities: []
};

export default memo(Category);
